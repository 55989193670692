import { PayloadAction } from "@reduxjs/toolkit";
import api from "../axios/middleWare";
import {
  IOrderReport,
  IOrderItemDetail,
} from "../../pages/orderReport/IOrderReport";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import {
  failureListOrderReport,
  successListOrderReport,
} from "./listOrderReport";

import {
  failureListOrderDataReport,
  successListOrderDataReport,
} from "./searchOrderReport";

// List Order DReport
const ListOrderReportApi = (action: PayloadAction<IOrderReport>) => {
  let payload: any = {
    retail_id: action.payload.retail_id,
    distributor_id: action.payload.distributor_id,
    entrydate: action.payload.entrydate,
  };
  return api.get("/api/retailorder/filter", { params: payload });
};

export function* ListOrderReportCall(action: PayloadAction<IOrderReport>) {
  try {
    const response: AxiosResponse = yield call(ListOrderReportApi, action);
    yield put(successListOrderReport(response.data));
  } catch (error) {
    yield put(failureListOrderReport(error));
  }
}

// Search Order DReport
const ListOrderDetaReportApi = (action: PayloadAction<string>) => {
  return api.get("/api/retailorder/filter", { params: action.payload });
};

export function* ListOrderDetaReportCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(ListOrderDetaReportApi, action);
    yield put(successListOrderDataReport(response.data));
  } catch (error) {
    yield put(failureListOrderDataReport(error));
  }
}
