import { createRef, FunctionComponent, useEffect, useState } from "react";
import {
  IOrderDetailMaster,
  OrderDetailMasterListProps,
} from "./IOrderDetailMaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import OrderDetailMasterForm from "./OrderDetailMasterForm";

const OrderDetailMasterList: FunctionComponent<OrderDetailMasterListProps> = (
  props
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IOrderDetailMaster>({
    active: true,
  });
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const dispatch = useAppDispatch();

  const { orderDetail } = props;
  console.log(">>>>>.......", orderDetail);

  const { data: orderDetailList, isLoading: listOrderDetailLoading } =
    useAppSelector((state) => state.ListOrderDetailMaster);

  const { data: createOrderDetail } = useAppSelector(
    (state) => state.CreateOrderDetailMaster
  );

  const { data: editOrderDetail } = useAppSelector(
    (state) => state.EditOrderDetailMaster
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  useEffect(() => {
    if (
      Object.keys(editOrderDetail ?? {}).length ||
      Object.keys(createOrderDetail ?? {}).length
    ) {
      handleClose();
    }
  }, [editOrderDetail, createOrderDetail]);
  return (
    <>
      <div
        className="col-7 tracking pt-4 px-4"
        style={{
          height: "100vh",
        }}
      >
        <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
          {/* {!open && ( 
            <div
              className="mx-4 add-branch"
              onClick={() => {
                handleClickOpen();
              }}
            >
              Add Order
            </div>
          )}*/}
          <div>
            {/* <FontAwesomeIcon
              className="mx-2"
              style={{
                fontSize: "22px",
                cursor: "pointer",
              }}
              icon={["fas", "pen-to-square"]}
              onClick={() => {
                handleClickOpen();
                setInitialValues(orderDetail ?? {});
              }}
            /> */}
          </div>
        </div>

        {!open ? (
          <>
            <div className="row">
              <div className="col-4 partner-detail-data mt-4">
                <label>Retailer Name</label>
                <p>{orderDetail?.retail?.name}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Order Number</label>
                <p>{orderDetail?.order_no}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Distributor Name</label>
                <p>{orderDetail?.distributor?.name}</p>
              </div>

              {/* <div className="col-4 partner-detail-data mt-4">
                <label>Entry Date</label>
                <p>
                  {orderDetail?.entrydate
                    ? new Date(orderDetail.entrydate).toLocaleDateString()
                    : ""}
                </p>
              </div> */}
              {/* <div className="col-4 partner-detail-data mt-4">
                <label>Status</label>
                <p>{orderDetail?.active ? "Active" : "InActive"}</p>
              </div> */}
            </div>

            <div className="row mt-5">
              {/* <label>Order Details</label> */}
              <div className="col-12 mt-4">
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "450px", overflowY: "auto" }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>Style_Color</TableCell>
                        <TableCell align="center">Quantity Of Size</TableCell>
                        <TableCell align="center">Sum Of Qty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetail?.order_detail?.map((row, index) => (
                        <TableRow
                          key={row?.id}
                          style={{ border: "1px solid #e0e0e0" }}
                        >
                          <TableCell
                            sx={{
                              padding: "0 16px",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0 16px",
                            }}
                          >
                            {row?.product}
                          </TableCell>
                          <TableCell
                            style={{
                              textOverflow: "ellipsis !important",
                              overflow: "hidden !important",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row?.style + "_" + row?.color}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="d-flex align-items-center justify-content-center"
                            style={{ gap: "10px", border: "none" }}
                            sx={{
                              padding: "16px",
                            }}
                          >
                            {row?.qty_sizes?.map((data) => (
                              <span key={data.id}>
                                <b>{data.size}</b> : {data.qty}
                              </span>
                            ))}
                          </TableCell>
                          <TableCell align="center">
                            {row?.qty_sizes?.reduce(
                              (sum, data) => sum + Number(data.qty),
                              0
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div
                className="col-12 mt-5"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0 20px",
                }}
              >
                <label>
                  Toatal Quantity = <span>{orderDetail?.totalqty}</span>
                </label>
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex">
            <OrderDetailMasterForm
              open={open}
              initialValues={initialValues}
              handleClose={handleClose}
              setInitialValues={setInitialValues}
            />
          </div>
        )}
      </div>

      {/* <>
        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle id="alert-dialog-title" className="font-weight-700">
              {"Add OrderDetails"}{" "}
            </DialogTitle>
            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <OrderDetailMasterForm
              initialValues={initialValues}
              handleClose={handleClose}
              setInitialValues={setInitialValues}
            />
          </DialogContent>
        </Dialog>

        <CompletePopup
          data={{ data: createOrderDetail }}
          responsePopup={Object.keys(createOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearCreateItemDetailMaster());
            setResponsePopup(false);
          }}
        />
        <CompletePopup
          data={{ data: editOrderDetail }}
          responsePopup={Object.keys(editOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearEditItemDetailMaster());
            setResponseEdit(false);
          }}
        />
      </> */}
    </>
  );
};

export default OrderDetailMasterList;
