import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IOrderReport } from "../../pages/orderReport/IOrderReport";
import { FAILED } from "../login-and-create-password/login-reducer";

const ListOrderReport = createSlice({
  name: "ListOrderReport",
  initialState: {} as IReduxState<IOrderReport[]>,
  reducers: {
    listOrderReportStart: (state, _) => {
      state.isLoading = true;
    },
    successListOrderReport: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      action.payload?.result?.length === 0 && FAILED(action.payload);
    },
    failureListOrderReport: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      FAILED(action.payload);
    },
    clearListOrderReport: (state) => {
      state.data = {};
    },
  },
});

export const {
  listOrderReportStart,
  successListOrderReport,
  failureListOrderReport,
  clearListOrderReport,
} = ListOrderReport.actions;

export default ListOrderReport.reducer;
