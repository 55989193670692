import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createRef,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  IOrderItemDetail,
  IOrderReport,
  IOrderReportProps,
} from "./IOrderReport";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import * as Yup from "yup";
import {
  useFormik,
  FieldArray,
  FormikProvider,
  FieldArrayRenderProps,
} from "formik";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Button } from "react-bootstrap";
import { createItemDetailMasterStart } from "../../redux/itemdetail-master/createItemDetailMaster";
import { editItemDetailMasterStart } from "../../redux/itemdetail-master/editItemDetailMaster";
import {
  clearEditOrderDetailMaster,
  editOrderDetailMasterStart,
} from "../../redux/orderdetail-master/editOrderDetailMaster";
import {
  clearCreateOrderDetailMaster,
  createOrderDetailMasterStart,
} from "../../redux/orderdetail-master/createOrderDetailMaster";
import {
  clearListOrderDetailItemMaster,
  listOrderDetailItemMasterStart,
} from "../../redux/orderdetail-master/searchOrderDetailItems";
import { clearListDistributorMaster } from "../../redux/distributor-master/listDistributorMaster";
import CompletePopup from "../components/response-pop-up";
import { clearListOrderDetailMaster } from "../../redux/orderdetail-master/listOrderDetailMaster";
import * as XLSX from "xlsx";

const OrderReportForm: FunctionComponent<IOrderReportProps> = (props) => {
  const { handleClose, setInitialValues, initialValues } = props;
  const [isEdit, setEdit] = useState<boolean>(false);
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const opetionList: any = useAppSelector((state) => state.GeneralSetting);

  const getFilteredData = (key: string) =>
    opetionList?.data?.result
      .filter((e: any) => e.page === "Item Detail Master" && e.key === key)
      .map((e: any) => ({ label: e.description, value: e.name }));

  // const itemStyle = getFilteredData("Item_Style");
  // const itemColor = getFilteredData("Item_Color");
  const itemSize = getFilteredData("Item_Size");

  const formik = useFormik<IOrderReport>({
    enableReinitialize: true,
    initialValues: {
      entrydate: "",
      retail_id: "",
      distributor_id: "",
      order_detail: [],

      active: initialValues.active ? initialValues.active : true,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    setValues,
    validateForm,
  } = formik;
  console.log("error", errors);

  

  const handleDownload = () => {
    if (!values?.order_detail || values.order_detail.length === 0) {
      alert("No data available to download!");
      return;
    }

    let data: {
      "S.No": number;
      "Entry Date": string;
      "Retailer Name": string;
      "Retailer Type": string;
      "Distributor Name": string;
      "Distributor Type": string;
      Item: string;
      Style_Color: string;
      Barcode: string;
      Size?: string;
      Quantity?: number;
      MRP: string;
    }[] = [];
    values.order_detail.forEach((row, index) => {
      if (!row?.qty_sizes || row.qty_sizes.length === 0) {
        // If no sizes are present, add a single row with empty sizes
        data.push({
          "S.No": index + 1,
          "Entry Date": values?.entrydate
            ? new Date(values.entrydate).toLocaleDateString()
            : "",
          "Retailer Name": values?.retail?.name || "",
          "Retailer Type": values?.retail?.type || "",
          "Distributor Name": values?.distributor?.name || "",
          "Distributor Type": values?.distributor?.type || "",

          Item: row?.product || "",
          Style_Color: `${row?.style}_${row?.color}`,
          Barcode: values?.retail?.name || "",
          MRP: values?.distributor?.name || "",
        });
      } else {
        // If there are multiple size-quantity pairs, create separate rows
        row.qty_sizes.forEach((sizeData) => {
          data.push({
            "S.No": index + 1,
            "Entry Date": values?.entrydate
              ? new Date(values.entrydate).toLocaleDateString()
              : "",
            "Retailer Name": values?.retail?.name || "",
            "Retailer Type": values?.retail?.type || "",
            "Distributor Name": values?.distributor?.name || "",
            "Distributor Type": values?.distributor?.type || "",

            Item: row?.product || "",
            Style_Color: `${sizeData?.style}_${sizeData?.color}`,
            Barcode: `${sizeData?.barcode}` || "",
            Size: sizeData.size,
            Quantity: sizeData.qty,
            MRP: `${sizeData?.mrp}` || "",
          });
        });
      }
    });

    if (data.length === 0) {
      alert("No data available to download!");
      return;
    }

    // Create a new worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Order Report");

    // Save the Excel file
    XLSX.writeFile(wb, "OrderReport.xlsx");
  };

  useEffect(() => {
    if (initialValues?.id) {
      const order_details = initialValues.order_detail?.reduce(
        (accumulater: IOrderItemDetail[], currentValue) => {
          const findVal = accumulater.find(
            (data) =>
              data.style === currentValue.style &&
              data.color === currentValue.color
          );
          if (findVal) {
            findVal.qty_sizes?.push(currentValue);
          } else {
            accumulater.push({ ...currentValue, qty_sizes: [currentValue] });
          }
          return accumulater;
        },
        []
      );
      setValues({ ...initialValues, order_detail: order_details });
    }
    return () => {
      setInitialValues({});
      dispatch(clearListOrderDetailItemMaster());
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} style={{ marginTop: "-15px" }}>
        <div className="d-flex flex-column">
          <div className="col-12 d-flex justify-content-end">
            <FontAwesomeIcon
              className="mx-2"
              style={{
                fontSize: "21px",
                cursor: "pointer",
              }}
              icon={["fass", "download"]}
              onClick={handleDownload}
            />
          </div>
          <div className="row mt-2">
            <div className="input-field px-3 col-4 d-flex flex-row align-items-center">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Retailer Name :
              </label>
              <p style={{ marginBottom: "0" }}>{values?.retail?.name}</p>
            </div>

            <div className="input-field px-3 col-4 d-flex flex-row align-items-center">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Entry Date :
              </label>
              <p style={{ marginBottom: "0" }}>
                {values?.entrydate
                  ? new Date(values.entrydate).toLocaleDateString()
                  : ""}
              </p>
            </div>
            <div className="input-field px-3 col-4 d-flex flex-row align-items-center">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Distributor Name :
              </label>
              <p style={{ marginBottom: "0" }}>{values?.distributor?.name}</p>
            </div>

            <div className="col-12 mt-2">
              <div className="col-6 d-flex ms-3 align-items-center"></div>
            </div>

            <div className=" mt-4" style={{ width: "94%", margin: "0 3%" }}>
              <TableContainer
                component={Paper}
                style={{
                  maxHeight: "450px",
                  overflowY: "auto",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell style={{ paddingLeft: "30px" }}>
                        Item
                      </TableCell>
                      <TableCell align="center">Style_Color</TableCell>
                      <TableCell align="center">Quantity Of Sizes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values?.order_detail?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        style={{ border: "1px solid #e0e0e0" }}
                      >
                        <TableCell
                          sx={{
                            padding: "4px 16px",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 16px",
                          }}
                        >
                          {row?.product}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "4px 16px",
                          }}
                        >
                          {row?.style + "_" + row?.color}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            padding: "4px 16px",
                            display: "flex",
                            borderBottom: "none",
                            gap: "10px",
                            border: "none",
                          }}
                        >
                          {row?.qty_sizes?.map((data) => (
                            <span key={data.id}>
                              <b>{data.size}</b> : {data.qty}
                            </span>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        {/* <CompletePopup
          data={{ data: createOrderDetail }}
          responsePopup={Object.keys(createOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearCreateOrderDetailMaster());
            createOrderDetail?.status && handleClose();
            setResponsePopup(false);
          }}
        />
        <CompletePopup
          data={{ data: editOrderDetail }}
          responsePopup={Object.keys(editOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearEditOrderDetailMaster());
            editOrderDetail?.status && handleClose();
            setResponseEdit(false);
          }}
        /> */}
      </form>
    </>
  );
};

export default OrderReportForm;
