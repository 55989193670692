import { PayloadAction } from "@reduxjs/toolkit";
import api from "../axios/middleWare";
import {
  IOrderDetailMaster,
  IOrderItemDetail,
} from "../../pages/orderDetailMaster/IOrderDetailMaster";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import {
  failureCreateOrderDetailMaster,
  successCreateOrderDetailMaster,
} from "./createOrderDetailMaster";
import {
  failureListOrderDetailMaster,
  successListOrderDetailMaster,
} from "./listOrderDetailMaster";
import {
  failureEditOrderDetailMaster,
  successEditOrderDetailMaster,
} from "./editOrderDetailMaster";
import {
  failureDeleteOrderDetailMaster,
  successDeleteOrderDetailMaster,
} from "./deleteOrderDetailMaster";
import {
  failureListOrderDetailItemMaster,
  successListOrderDetailItemMaster,
} from "./searchOrderDetailItems";
import {
  failureFilterOrderDetailMaster,
  successFilterOrderDetailMaster,
} from "./filterOrderDetailMaster";

// List Order Detail master
const ListOrderDetailMasterApi = (
  action: PayloadAction<IOrderDetailMaster>
) => {
  return api.get("/api/retailorder/list", { params: action.payload });
};

export function* ListOrderDetailMasterCall(
  action: PayloadAction<IOrderDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      ListOrderDetailMasterApi,
      action
    );
    yield put(successListOrderDetailMaster(response.data));
  } catch (error) {
    yield put(failureListOrderDetailMaster(error));
  }
}

// Create Order Detail master
const CreateOrderDetailMasterApi = (
  action: PayloadAction<IOrderDetailMaster>
) => {
  return api.post("/api/retailorder/create", action.payload);
};

export function* CreateOrderDetailMasterCall(
  action: PayloadAction<IOrderDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      CreateOrderDetailMasterApi,
      action
    );
    yield put(successCreateOrderDetailMaster(response.data));
  } catch (error) {
    yield put(failureCreateOrderDetailMaster(error));
  }
}

// update Order Detail master
const UpdateOrderDetailMasterApi = (
  action: PayloadAction<IOrderDetailMaster>
) => {
  return api.patch("/api/retailorder/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* UpdateOrderDetailMasterCall(
  action: PayloadAction<IOrderDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      UpdateOrderDetailMasterApi,
      action
    );
    yield put(successEditOrderDetailMaster(response.data));
  } catch (error) {
    yield put(failureEditOrderDetailMaster(error));
  }
}

// Delete Order Detail master
const DeleteOrderDetailMasterApi = (
  action: PayloadAction<IOrderDetailMaster>
) => {
  return api.delete("/api/retailorder/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteOrderDetailMasterCall(
  action: PayloadAction<IOrderDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      DeleteOrderDetailMasterApi,
      action
    );
    yield put(successDeleteOrderDetailMaster(response.data));
  } catch (error) {
    yield put(failureDeleteOrderDetailMaster(error));
  }
}

// Search Order Detail master
const ListOrderDetailItemMasterApi = (action: PayloadAction<string>) => {
  let payload: any = {
    barcode: action.payload,
  };
  return api.get("/api/itemdetail/filter", { params: payload });
};

export function* ListOrderDetailItemMasterCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(
      ListOrderDetailItemMasterApi,
      action
    );
    yield put(successListOrderDetailItemMaster(response.data));
  } catch (error) {
    yield put(failureListOrderDetailItemMaster(error));
  }
}

// filter Order Detail master
const FilterOrderDetailMasterApi = (action: PayloadAction<string>) => {
  return api.get("/api/itemdetail/filter", {
    params: {
      barcode: action.payload,
    },
  });
};

export function* FilterOrderDetailMasterCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(
      FilterOrderDetailMasterApi,
      action
    );
    yield put(successFilterOrderDetailMaster(response.data));
  } catch (error) {
    yield put(failureFilterOrderDetailMaster(error));
  }
}
