import {
  createRef,
  FunctionComponent,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  IOrderItemDetail,
  IOrderReport,
  OrderReportListProps,
} from "./IOrderReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Checkbox, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import * as XLSX from "xlsx";

import OrderReportForm from "./OrderReportForm";

const OrderReportList: FunctionComponent<OrderReportListProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IOrderReport>({
    active: true,
  });
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const dispatch = useAppDispatch();

  const { orderReport } = props;

  const { data: orderDetailList, isLoading: listOrderrEPORTLoading } =
    useAppSelector((state) => state.ListOrderReport);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  // console.log("values", values);

  const [selectedRows, setSelectedRows] = useState<IOrderReport[]>([]);
  console.log("rows", selectedRows);

  const handleSelectRow = (row: IOrderReport) => {
    console.log("selected rowws", row);
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((selRow) => selRow.id === row.id);

      if (isSelected) {
        // Remove the row if already selected (uncheck)
        return prevSelected.filter((selRow) => selRow.id !== row.id);
      } else {
        // Add the row if not selected (check)
        return [...prevSelected, row];
      }
    });
  };

  useImperativeHandle(props.downloadClick, () => ({
    downloadAction() {
      console.log("download triggred");
      if (selectedRows.length === 0) {
        alert("No data available to download!");
        return;
      }

      let data: {
        "S.No": number;
        "Entry Date": string;
        "Retailer Name": string;
        "Retailer Type": string;
        "Distributor Name": string;
        "Distributor Type": string;
        "Order Number": string;
        Item: string;
        Style_Color: string;
        Barcode: string;
        Size?: string;
        Quantity?: number;
        MRP: string;
      }[] = [];

      selectedRows.forEach((values, orderIndex) => {
        values?.order_detail?.forEach((row, index) => {
          if (row?.qty_sizes && row.qty_sizes.length > 0) {
            // If `qty_sizes` exists, iterate over it
            row.qty_sizes.forEach((sizeData, sizeIndex) => {
              data.push({
                "S.No": index + 1, // Ensure serial numbers are unique
                "Order Number": values?.order_no || "",
                "Entry Date": values?.entrydate
                  ? new Date(values.entrydate).toLocaleDateString()
                  : "",
                "Retailer Name": values?.retail?.name || "",
                "Retailer Type": values?.retail?.type || "",
                "Distributor Name": values?.distributor?.name || "",
                "Distributor Type": values?.distributor?.type || "",
                Item: sizeData?.product || row?.product || "",
                Style_Color: `${sizeData?.style || row?.style}_${
                  sizeData?.color || row?.color
                }`,
                Barcode: `${sizeData?.barcode || row?.barcode || ""}`,
                Size: sizeData?.size || "",
                Quantity: sizeData?.qty || 0,
                MRP: `${sizeData?.mrp || row?.mrp || ""}`,
              });
            });
          } else {
            // If no `qty_sizes`, push regular order detail
            data.push({
              "S.No": data.length + 1,
              "Order Number": values?.order_no || "",
              "Entry Date": values?.entrydate
                ? new Date(values.entrydate).toLocaleDateString()
                : "",
              "Retailer Name": values?.retail?.name || "",
              "Retailer Type": values?.retail?.type || "",
              "Distributor Name": values?.distributor?.name || "",
              "Distributor Type": values?.distributor?.type || "",
              Item: row?.product || "",
              Style_Color: `${row?.style}_${row?.color}`,
              Barcode: `${row?.barcode || ""}`,
              Size: row?.size || "",
              Quantity: row?.qty || 0,
              MRP: `${row?.mrp || ""}`,
            });
          }
        });
      });

      if (data.length === 0) {
        alert("No data available to download!");
        return;
      }

      // Create a new worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a new workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Order Report");

      // Save the Excel file
      XLSX.writeFile(wb, "OrderReport.xlsx");
    },
  }));

  return (
    <>
      <div
        className="col-12 tracking pt-4 px-4"
        style={{
          height: "100vh",
        }}
      >
        <>
          <div className="row ">
            <div className="col-12 ">
              <TableContainer
                component={Paper}
                style={{ maxHeight: "450px", overflowY: "auto" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ paddingLeft: "35px" }}>
                        S.No
                      </TableCell>
                      <TableCell>Retailer</TableCell>
                      <TableCell>Order No</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetailList?.result?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        style={{ border: "1px solid #e0e0e0" }}
                      >
                        <TableCell
                          sx={{
                            padding: "0 16px",
                          }}
                        >
                          <Checkbox
                            checked={
                              !!selectedRows.find(
                                (selRow) => selRow.id === row.id
                              )
                            } // Convert undefined to false
                            onChange={() => handleSelectRow(row)}
                          />
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 16px",
                          }}
                        >
                          {row?.retail?.name}
                        </TableCell>
                        <TableCell
                          style={{
                            textOverflow: "ellipsis !important",
                            overflow: "hidden !important",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row?.order_no}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-flex align-items-center justify-content-center"
                          style={{ gap: "10px", border: "none" }}
                          sx={{
                            padding: "16px",
                          }}
                        >
                          {row?.entrydate
                            ? new Date(row.entrydate).toLocaleDateString()
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          <FontAwesomeIcon
                            className="mx-2"
                            style={{
                              fontSize: "12px",
                              cursor: "pointer",
                            }}
                            icon={["fas", "eye"]}
                            onClick={() => {
                              setOpen(true);
                              setInitialValues(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </>

        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle id="alert-dialog-title" className="font-weight-700">
              {"Order Details"}{" "}
            </DialogTitle>
            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <OrderReportForm
              open={open}
              initialValues={initialValues}
              handleClose={handleClose}
              setInitialValues={setInitialValues}
            />
          </DialogContent>
        </Dialog>
      </div>

      {/*
       <>
      

        <CompletePopup
          data={{ data: createOrderDetail }}
          responsePopup={Object.keys(createOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearCreateItemDetailMaster());
            setResponsePopup(false);
          }}
        />
        <CompletePopup
          data={{ data: editOrderDetail }}
          responsePopup={Object.keys(editOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearEditItemDetailMaster());
            setResponseEdit(false);
          }}
        />
      </> */}
    </>
  );
};

export default OrderReportList;
