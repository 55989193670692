import {
  Autocomplete,
  Backdrop,
  Box,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { useSearchParams } from "react-router-dom";
import { IOrderReport, IOrderItemDetail, FilterDetils } from "./IOrderReport";

import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import OrderReportList from "./OrderReportList";

import { listItemDetailMasterStart } from "../../redux/itemdetail-master/listItemDetailMaster";
import {
  clearListOrderReport,
  listOrderReportStart,
} from "../../redux/order-report/listOrderReport";

import { listRetailerMasterStart } from "../../redux/retail-master/listRetailerMaster";
import OrderReportForm from "./OrderReportForm";

import { clearListOrderDetailItemMaster } from "../../redux/orderdetail-master/searchOrderDetailItems";
import { listDistributorMasterStart } from "../../redux/distributor-master/listDistributorMaster";
import { DateRange } from "@mui/lab";
import * as XLSX from "xlsx";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker"
import { Range, RangeKeyDict } from "react-date-range";
import * as Yup from "yup";
import { useFormik } from "formik";




const OrderReport: FunctionComponent = () => {
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IOrderReport>({
    active: true,
  });
  const [isEdit, setEdit] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterDetils>();
  const [dateRange, setDateRange] = useState<Range | undefined>();

  const dispatch = useAppDispatch();

  const { data: retailerList } = useAppSelector(
    (state) => state.ListRetailerMaster
  );

  const { data: distributorList } = useAppSelector(
    (state) => state.ListDistributorMaster
  );
  const { data: orderDetailList, isLoading: listOrderReportLoading } =
    useAppSelector((state) => state.ListOrderReport);

  const distributor_option = distributorList?.result?.map((distributor) => ({
    label: distributor.name,
    value: distributor.id,
  }));

  const retailer_option = retailerList?.result?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const listOrderDetail =
    orderDetailList?.result?.map((orderDetail, index) => ({
      ...orderDetail,
      index: index + 1,
    })) ?? [];

  const downloadButtonClick = useRef<{ downloadAction: () => void }>(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handleDownloadClick = () => {
    if (downloadButtonClick.current) {
      downloadButtonClick.current.downloadAction();
    }
  };

  //clear create edit data and get the state list
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    dispatch(listItemDetailMasterStart(null));
    dispatch(listRetailerMasterStart(null));
    dispatch(listDistributorMasterStart(null));

    return () => {
      dispatch(clearListOrderDetailItemMaster());
    };
  }, []);
  const [error, setError] = useState<string>("");

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    let endDate = filter?.entrydate?.end;
    if (!endDate) {
      endDate = newStartDate; // If end date is empty, set it to start date
    }

    if (endDate && newStartDate > endDate) {
      setError("Start date must be earlier than the end date.");
    } else {
      setError("");
      setFilter((prev) => ({
        ...prev,
        entrydate: { ...prev?.entrydate, start: newStartDate, end: endDate},
      }));
      setFieldValue("entrydate", {start:newStartDate,end:endDate})
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    let startDate = filter?.entrydate?.start;
    if (!startDate) {
      startDate = newEndDate; // If start date is empty, set it to end date
    }
    if (startDate && newEndDate < startDate) {
      setError("End date must be later than the start date.");
    } else {
      setError("");
      setFilter((prev) => ({
        ...prev,
        entrydate: { ...prev?.entrydate, start:startDate,end: newEndDate },
      }));
    }
  };

  //   validation
    const validationSchema = Yup.object().shape({
     
  
      retail_id: Yup.string().optional(),
      distributor_id: Yup.string().optional(),
     
    });
  
    const formik = useFormik<FilterDetils>({
      enableReinitialize: true,
      initialValues: {
        // entrydate: "",
        retail_id: "",
        distributor_id: "",
       entrydate:{},
        
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        console.log(values);
  
        
      },
    });
    const {
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      setFieldValue,
      setFieldTouched,
      resetForm,
      errors,
      setValues,
      validateForm,
    } = formik;
  return (
    <div className="d-flex">
      <>
        <div className="list col-12 ">
          <div className=" px-3 my-4  d-flex align-items-center">
            <div className="title d-flex ">
              <h5>Order Report</h5>
            </div>

            <form  onSubmit={handleSubmit}className="filter ms-auto me-3 d-flex align-items-center">
              <Autocomplete
                multiple
                limitTags={1}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  "& .MuiAutocomplete-input": {
                    minWidth: "110px !important",
                  },

                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  retailer_option?.filter((e) =>
                    filter?.retail_id?.includes(e.value as string)
                  ) ?? []
                }
                options={retailer_option ?? []}
                getOptionLabel={(option) => option?.label ?? ""}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, value) => {
                  const selectedValues = value.map((v: any) => v.value);
                  setFilter({ ...filter, retail_id: String(selectedValues) });
                  setFieldValue("distributor_id", selectedValues);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    name="retail_id"
                    placeholder="Select Retailer Type"
                    {...params}
                  />
                )}
              />
              <Autocomplete
                multiple
                limitTags={1}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  "& .MuiAutocomplete-input": {
                    minWidth: "110px !important",
                  },

                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  distributor_option?.filter((e) =>
                    filter?.distributor_id?.includes(e.value as string)
                  ) ?? []
                }
                options={distributor_option ?? []}
                getOptionLabel={(option) => option?.label ?? ""}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, value) => {
                  const selectedValues = value.map((v: any) => v.value);
                  setFilter({
                    ...filter,
                    distributor_id: String(selectedValues),
                  });
                  setFieldValue("distributor_id", selectedValues);

                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    name="distributor_id"
                    placeholder="Select Distributor Type"
                    {...params}
                  />
                )}
              />
              <div style={{border:"2px solid #c4c4c4" , borderRadius:"0px", display:"flex",alignItems:"center", margin:"0 14px"}}>
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "none",
                    // borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  "& input": {
                    height: "1em",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0px",
                }}
                name="entrydate_start"
                type="date"
                value={
                  filter?.entrydate
                    ? new Date(filter?.entrydate?.start ?? "").toLocaleDateString("en-CA")
                    : ""
                }
                // onChange={(e) => {
                //   // setFilter({ ...filter, entrydate: {start:e.target.value} });
                //   setFilter((prev) => ({ ...prev, entrydate: { ...prev?.entrydate, start: e.target.value } }));

                // }}
                onChange={handleStartDateChange}
                
              />
              <span>-</span>
               <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "none",
                    // borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  "& input": {
                    height: "1em",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0px",
                }}
                name="entrydate_end"
                type="date"
                value={
                  filter?.entrydate
                    ? new Date(filter?.entrydate?.end ??  "").toLocaleDateString("en-CA")
                    : ""
                }
               
                onChange={handleEndDateChange}
                inputProps={{
                  min: filter?.entrydate?.start || "", // Set the min date to start date
                }}
              />
              </div>
             
              {/* <div
                className="mx-2 add-branch"
                title="Find"
                style={{ padding: "7px 7px !important" }}
                aria-disabled={values ? false: true}
                onClick={() => {
                  dispatch(listOrderReportStart(filter));
                  handleSubmit();
                    validateForm();
                }}
              >
                Submit
              </div> */}
              <div
  className="mx-2 add-branch"
  title="Find"
  style={{
    padding: "7px 7px !important",
    opacity:
      filter?.retail_id?.length ||
      filter?.distributor_id?.length ||
      (filter?.entrydate?.start && filter?.entrydate?.end)
        ? 1
        : 0.5,
    pointerEvents:
      filter?.retail_id?.length ||
      filter?.distributor_id?.length ||
      (filter?.entrydate?.start && filter?.entrydate?.end)
        ? "auto"
        : "none",
  }}
  onClick={() => {
    if (
      filter?.retail_id?.length ||
      filter?.distributor_id?.length ||
      (filter?.entrydate?.start && filter?.entrydate?.end)
    ) {
      dispatch(listOrderReportStart(filter));
      handleSubmit();
      validateForm();
    }
  }}
>
  Submit
</div>

              <FontAwesomeIcon
                onClick={() => {
                  setFilter({});
                  dispatch(clearListOrderReport());
                }}
                className="mx-2"
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                icon={["fas", "arrows-rotate"]}
              />
              <FontAwesomeIcon
                onClick={handleDownloadClick}
                className="mx-2"
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                icon={["fas", "download"]}
              />
            </form>
          </div>
          <div
            className="list"
            style={{
              overflow: "scroll",
              height: "80vh",
              background: "#fafafa",
            }}
          >
            <OrderReportList downloadClick={downloadButtonClick} />
          </div>
        </div>
      </>

      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!listOrderReportLoading}
        >
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
      </>
    </div>
  );
};

export default OrderReport;
